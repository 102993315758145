import {Component, Inject, Input, OnInit} from '@angular/core';
import {NbToastrService} from "@nebular/theme";
import {FormService} from "../../../services/form.service";
import {ApiService} from "../../../services/api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {User} from "../../../models/user";
import {
  faArrowLeftFromBracket,
  faInfoCircle,
  faPaperPlaneTop
} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";
import {DagWerfPlanning} from "../../../models/werfplanning";

@Component({
  selector: 'send-pushnotifications-dialog',
  templateUrl: './send-pushnotifications-dialog.html',
  styleUrls: ['./send-pushnotifications-dialog.scss']
})
export class SendPushnotificationsDialog implements OnInit {
    usersWithFcmToken: User[];
  arbeidersWithoutFcmToken:  User[];
  chauffeursWithoutFcmToken:  User[];
  onderaannemersWithoutFcmToken:  User[];
  isSaving: boolean = false;
  isConfirmed: boolean = false;
  private currentDagWerfplanning: DagWerfPlanning;
  showPushNotificationInfo: boolean = false;
  constructor(
    private apiService: ApiService,
    private formService: FormService,
    private toastrService: NbToastrService,
    private dialogRef: MatDialogRef<SendPushnotificationsDialog>,
     @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentDagWerfplanning = this.data.dagWerfPlanning;
    this.usersWithFcmToken = this.data.usersWithFcmToken;
    this.arbeidersWithoutFcmToken = this.data.arbeidersWithoutFcmToken;
    this.chauffeursWithoutFcmToken = this.data.chauffeursWithoutFcmToken;
    this.onderaannemersWithoutFcmToken = this.data.onderaannemersWithoutFcmToken;
  }

  ngOnInit(): void {
  }


  protected readonly faPaperPlaneTop = faPaperPlaneTop;


  sendPushNotificationsToEmployees() {
    if(this.isSaving){
      return;
    }
    this.isSaving = true;

    this.apiService.sendPushNotificationsToEmployees(this.usersWithFcmToken,this.arbeidersWithoutFcmToken, this.currentDagWerfplanning._id).subscribe((x) => {
      console.log(x)
      let response = x as any;
      this.toastrService.success('Push notificaties zijn succesvol verstuurd', 'Push notificaties versturen');
      this.isSaving = false;
      this.isConfirmed = true;
      this.dialogRef.close({
        pushNotificationsSend: response.pushNotificationsSend,
        successfulPushNotficationUsers: response.successfulPushNotficationUsers,
        failedPushNotficationUsers: response.failedPushNotficationUsers,
        arbeidersWithoutFcmToken: response.arbeidersWithoutFcmToken
      });
    }, error => {
      if(error.status === 400 && error.error === 'Geen gebruikers gevonden'){
        this.toastrService.danger('Er zijn geen gebruikers gevonden om push notificaties naar te versturen', 'Error');
        this.isSaving = false;
        return;
      } else {
        this.toastrService.danger('Er is een fout opgetreden bij het versturen van de push notificaties..Gelieve te mailen naar info@selux-tools.com', 'Error');
      }
    });
  }



  protected readonly faArrowLeftFromBracket = faArrowLeftFromBracket;
  protected readonly faInfoCircle = faInfoCircle;
}
