import {Werf} from "./werf";
import {User} from "./user";

export class Ploeg {
  ploegbaas: User;
  voorkeurArbeiders: User[];
  enkeleArbeider: User;
}
export class WerfPlanning {
  public werf: Werf;
  selectedArbeiders: User[];
  selectedPloegen: Ploeg[];
  opmerkingAanArbeiders: string;
  specialeInstructies?: string;
  constructor() {
  }
}

export class DagWerfPlanning {
  _id: string;
  isoDate: string;
  werfPlanningen: WerfPlanning[];
  totalSelectedArbeiders: number;
  totalSelectedChauffeurs: number;
  totalSelectedOnderaannemers: number;
  verlofArbeiders: User[];
  company_id: string;
  created: string;
  deleted: boolean;
  pushNotificationsSend: boolean;
  successfulPushNotficationUsers: string[];
  failedPushNotficationUsers: string[];
  arbeidersWithoutFcmToken: string[];
}
export class MaandWerfPlanning {
  _id: string;
  isoDate: string;
  dagWerfPlanningen: DagWerfPlanning[];
  deleted: boolean;
  company_id: string;
}
