import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import {Observable, of} from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {ApiService} from "../../services/api.service";
import {Company} from "../../models/company";
import {FormService} from "../../services/form.service";
import {Bedrijf} from "../../models/E-digid/bedrijf";
import {MaakConnectieDialogComponent} from "./maak-connectie-dialog/maak-connectie-dialog.component";
import {NbToastrService} from "@nebular/theme";
import {BedrijfExistsDialogComponent} from "./bedrijf-exists.dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {faArrowLeftFromBracket, faCirclePlus} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";

@Component({
  selector: 'ngx-klanten',
  templateUrl: './klanten.component.html',
  styleUrls: ['./klanten.component.scss', '../../styles/item-table.scss']
})
export class KlantenComponent implements OnInit {
  @ViewChild('autoInput') input;

  public klantItem: Bedrijf = new Bedrijf('','');
  public klanten: Array<Bedrijf> = [];
  public isOn: Boolean;
  searchForm = new UntypedFormControl();
  filterSelect: any;
  filterItems: Bedrijf[] = [];
  observableFilteredItems$: Observable<string[]>;
  isLoaded: boolean;
  company: Company;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private formService: FormService,
    private dialog: MatDialog,
    private toast: NbToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoaded = false;
    while(this.apiService.thisCompany == null){
      await this.delay(50);
    }
    this.company = this.apiService.thisCompany;
    this.apiService.getBedrijven().subscribe(x => {
      this.klanten = x as Bedrijf[];
      if(this.company.bedrijfsGegevens != null){
        this.klanten = this.klanten.filter(x => x._id !== this.company.bedrijfsGegevens?._id);
      }
      for(let klant of this.klanten){
        if(klant.realBedrijf != null){
          klant.naam = klant.realBedrijf.naam;
          klant.email = klant.realBedrijf.email;
          klant.straat = klant.realBedrijf.straat;
          klant.huisNr = klant.realBedrijf.huisNr;
          klant.postcode = klant.realBedrijf.postcode;
          klant.land = klant.realBedrijf.land;
          klant.gemeente = klant.realBedrijf.gemeente;
          klant.vestigingen = klant.realBedrijf.vestigingen;
          klant.ondernemingsNummer = klant.realBedrijf.ondernemingsNummer;
          klant.btwNummer = klant.realBedrijf.btwNummer;
        }
      }
      this.sortKlanten();
      this.formService.allBedrijven = this.klanten;
      this.filterItems = this.klanten;
      this.observableFilteredItems$ = of(this.filterItems.map(x => x.naam));
      if(this.formService.previousIndexScroll != null && this.formService.previousPageForScrollIndex === 'klanten'){
        const rows = document.getElementsByClassName("klant");
        setTimeout( () => {
          rows[this.formService.previousIndexScroll].scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
          this.formService.previousIndexScroll = null;
        }, 100 );
      }
    });
    this.isLoaded = true;
  }

  onClick(werfData) {
    const _id: string = werfData._id;
    this.router.navigate(['/pages/editklant', _id]);
  }

  async onAddKlant() {
      this.formService.allBedrijven.push(this.formService.latestAddBedrijf);
      this.formService.allBedrijven = this.formService.allBedrijven.sort((a,b) => a.naam.toLowerCase().localeCompare(b.naam.toLowerCase()));
      this.klanten = this.formService.allBedrijven;
      this.filterItems = this.klanten;
      this.observableFilteredItems$ = of(this.filterItems.map(x => x.naam));
  }

  sortKlanten() {
    this.klanten.sort((a,b) => a.naam.toLowerCase().localeCompare(b.naam.toLowerCase()));
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.filterItems.filter(optionValue => optionValue.naam.toLowerCase().includes(filterValue)).map(optionValue => optionValue.naam);
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.observableFilteredItems$ = this.getFilteredOptions(this.input.nativeElement.value);
  }

  clearAutocomplete() {
    this.input.nativeElement.value = '';
    this.filterItems = this.klanten;
    this.observableFilteredItems$ = of(this.filterItems.map(x => x.naam));
  }

  clickAutoCompleteBedrijf($event) {
    let werfName = $event;
    if(werfName != null && werfName !== ''){
      let werf = this.klanten.find(x => x.naam === this.input.nativeElement.value);
      if(werf != null){
        this.onClick(werf)
      }
    }
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  maakVerbinding(bedrijf: Bedrijf) {
    this.apiService.checkIfVatAlreadyExists(bedrijf.btwNummer).subscribe(x => {

      if(x != null){
        let existingBedrijf = x as Bedrijf;
        const dialogRef = this.dialog.open(BedrijfExistsDialogComponent, {
          data: {title: 'Gekend bedrijf', message: 'Dit bedrijf bestaat al in ons huidige systeem.\nWilt u een connectie maken met dit bedrijf?', bedrijf: existingBedrijf},
          width: '500px',
          height:'300px'
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          if (result) {

            bedrijf.realBedrijf = existingBedrijf;
            this.apiService.updateBedrijf(bedrijf).subscribe(async x => {
              this.toast.success('Succesvol connectie gemaakt met ' + existingBedrijf.naam, 'Connectie gemaakt');
            });
          }
        });
      } else {
        const dialogRef = this.dialog.open(MaakConnectieDialogComponent, {
          width: '560px',
          height:'850px',
          data: {name: '', email: bedrijf.email? bedrijf.email: '', message: `Beste ${bedrijf.naam},\n\nU wordt uitgenodigd op het platform van Selux werklogger voor de digitale registratie van afvalstoffen.\n
         Verstuur door: ${this.apiService.userName}\n
         Bedrijf: ${this.company.name}
        \nGelieve een account aan te maken door op onderstaande link te klikken.`}
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('De dialoog werd gesloten', result);
          if(result != null){
            result.message = result.message.replace('digitale registratie van afvalstoffen', `<b>digitale registratie van afvalstoffen</b>`);
            result.message = result.message.replace(this.company.name, `<b>${this.company.name}</b>`);
            result.message = result.message.replace('onderstaande link te klikken', `<b>onderstaande link te klikken</b>`);
            bedrijf.tempMessage = result.message;
            this.apiService.sendMailToInviteCompany(result.name, result.email, this.company._id,bedrijf ).subscribe(x => {
              console.log(x);
              let updatedBedrijf = x as Bedrijf;
              if(updatedBedrijf != null){
                let index = this.klanten.findIndex(x => x._id === bedrijf._id);
                this.klanten[index].tempRegistrationCode = updatedBedrijf.tempRegistrationCode;
                this.toast.success('De uitnodiging werd verstuurd naar ' + result.email, 'Uitnodiging verstuurd');
              }
            }, error => {
              this.toast.danger('Er is een fout opgetreden bij het versturen van de uitnodiging naar ' + result.email + ', controleer alle ingevulde velden', 'Uitnodiging niet verstuurd');
            });
          }
        });
      }
    });
  }

  stelEigenBedrijfIn() {
      this.formService.isEigenBedrijf = true;
    this.formService.lastPage = 'klanten';
    this.router.navigate(['/pages/editEigenBedrijf']);
    }

  addKlant() {
    this.isOn = true;
    this.formService.isEigenBedrijf = false;
  }

    protected readonly faCirclePlus = faCirclePlus;
  protected readonly faArrowLeftFromBracket = faArrowLeftFromBracket;
}
