import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormService} from "../../services/form.service";


@Component({
  selector: 'delete-dialog-werfplanning',
  template: `<h2 mat-dialog-title style=" display: flex;
      align-items: center;
      color: #f44336;
      margin-bottom: 8px;">
  <mat-icon style="margin-right: 8px" color="warn">warning</mat-icon>
  Attentie!
</h2>
<mat-dialog-content>
  <p style=" font-size: 16px; margin-bottom: 16px;">Bent u zeker dat u deze werfplanning wilt verwijderen?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false">Annuleren</button>
  <button style="margin-right: 5px;margin-left: 15px" mat-raised-button color="warn" [mat-dialog-close]="true">
    <mat-icon>delete</mat-icon>
    Verwijderen
  </button>
</mat-dialog-actions>`
  })
export class DeleteDialogWerfplanning {
      constructor(
      public dialogRef: MatDialogRef<DeleteDialogWerfplanning>,
      public formService: FormService
      ) {
      this.formService.isDelete = false;
      }

      onNoClick(): void {
      this.formService.isDelete = false;
      this.dialogRef.close();
      }

      onDeleteClick() {
      this.formService.isDelete = true;
      this.dialogRef.close();
      }
}
