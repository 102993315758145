import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {Company} from "../../models/company";
import {VasteVerlofdagenBouwService} from "../../services/vasteVerlofdagenBouw.service";
import {BehaviorSubject, forkJoin, mapTo, Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";
import {Werf} from "../../models/werf";
import {DagWerfPlanning, MaandWerfPlanning, Ploeg, WerfPlanning} from "../../models/werfplanning";
import {User} from "../../models/user";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {Router} from "@angular/router";
import {
  faArrowDown,
  faArrowLeftFromArc,
  faArrowLeftFromBracket, faArrowsMinimize, faBuilding, faCalendar,
  faCirclePlus,
  faCopy, faFilePdf,
  faFloppyDisk, faHandshake, faMapLocationDot, faMinimize, faPaperPlaneTop,
  faRemove, faUmbrella,
  faUmbrellaBeach
} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";
import {NbToastrService} from "@nebular/theme";
import {faUser} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";
import * as html2pdf from 'html2pdf.js';
import {HasChangedPopupComponent} from "../has-changed-popup/has-changed-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogWerfplanning} from "./delete-werfplanning-dialog.component";
import {faHardHat, faTruck} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/regular";
import {SendPushnotificationsDialog} from "./send-pushnotifications-dialog/send-pushnotifications-dialog";
import {HandleidingComponent} from "./handleiding/handleiding.component";
import {Verlof} from "../../models/verlof";


class DayInMonth {
  date: Date;
  day: string;
  isoDate: string;
  isEmpty: boolean;
  soort: string;
  totalSelectedArbeiders: number;
  totalSelectedOnderaannemers: number;
  totalSelectedChauffeurs: number;
  verlofArbeiders: User[];
  dagWerfplanningId: string;
  totalWerfPlanningen: number;
}

@Component({
  selector: 'ngx-werfplanning',
  templateUrl: './werfplanning.component.html',
  styleUrls: ['./werfplanning.component.scss']
})
export class WerfplanningComponent implements OnInit {
  @ViewChild('tooltipTemplate', { static: true }) tooltipTemplate!: TemplateRef<any>;

  days: string[] = ['Zondag','Maandag','Dinsdag','Woensdag','Donderdag','Vrijdag','Zaterdag'];
  settingsForm: UntypedFormGroup;
  company: Company;
  isLoaded: boolean;
  isSaving: boolean = false;
  isViewingDay: boolean;
  maanden = ["Januari","Februari","Maart","April","Mei","Juni","Juli","Augustus","September", "Oktober","November","December"];
  todayDate: Date;
  jaren: number[] = [];
  currentDate: Date;
  maand: string;
  isDragging: boolean = false;
  showOnderaannemers: boolean = true;
  showChauffeurs: boolean = true;
  daysInMonth: BehaviorSubject<DayInMonth[]> = new BehaviorSubject<DayInMonth[]>(null);
  daysInMonth$: Observable<DayInMonth[]> = this.daysInMonth.asObservable();
  lastDayOfMonth: number;
  expandedTextareaId: string | null = null;
  jaar: string;
  werven: Werf[];
  werfPlanningen: WerfPlanning[] = [];
  allUsers: User[];
  allArbeiders: User[];
  isLoadedCount: number;
  assignmentForm: FormGroup;
  selectedDate: Date;
  unassignedArbeiders: User[];
  unassignedOnderaannemers: User[];
  unassignedChauffeurs: User[];
  unassignedArbeidersZonderPloeg: User[];
  selectedArbeiders: any[] = [];
  sortChoiceBeschikbareArbeiders: any;
  ploegen: Ploeg[] = [];
  selectedPloegen: Ploeg[] = [];
  maandWerfPlanning: MaandWerfPlanning;
  currentDagWerfPlanning: DagWerfPlanning;
  _id: string;
  copyWerfplanning: any;
  reverseDagplanningen: DagWerfPlanning[];
  isPrint: boolean;
  werfplanningForm: FormGroup;
  hasChangedValue: boolean;
  allOnderaannemers: User[];
  allChauffeurs: User[];
  protected readonly faFilePdf = faFilePdf;

  protected readonly faUser = faUser;
  protected readonly faUmbrellaBeach = faUmbrellaBeach;
  protected readonly faMapLocationDot = faMapLocationDot;
  protected readonly faCopy = faCopy;
  protected readonly faArrowLeftFromBracket = faArrowLeftFromBracket;
  constructor(
    private apiService: ApiService,
    private formService: FormService,
    private vasteVerlofDagenBouw: VasteVerlofdagenBouwService, private fb: FormBuilder,
    private cdr: ChangeDetectorRef, private router: Router, private toastr: NbToastrService, private dialog: MatDialog
  ) {
  }
  async ngOnInit() {
    this.isLoadedCount = 0;

    this.hasChangedValue = false;
    this.isPrint = false;
    this.todayDate = new Date();
    this.currentDate = new Date();
    this.jaren.push(this.todayDate.getFullYear() - 1);
    this.jaren.push(this.todayDate.getFullYear());
    //push de volgende 5 jaren
    for(let i = 1; i < 5; i++){
      this.jaren.push(this.todayDate.getFullYear() + i);
    }
    this.jaar = this.todayDate.getFullYear().toString();
    this.maand = this.maanden[this.currentDate.getMonth()];

    while(this.apiService.thisCompany == null){
      await this.delay(50);
    }
    this.company = this.apiService.thisCompany;

    this.sortChoiceBeschikbareArbeiders = 'naam';
    const users$ = this.allUsers == null ? this.apiService.getUsersByRoleLight('Ploegbaas&Arbeider').pipe(
      tap(x => {
        let allArbeiders = x as User[];
        allArbeiders = allArbeiders.filter(x => x.name !== 'bjorn massoels');
        this.allUsers = allArbeiders;
        this.allOnderaannemers = this.allUsers.filter(x => x.functieNaam === 'Onderaannemer');
        this.unassignedOnderaannemers = this.allUsers.filter(x => x.functieNaam === 'Onderaannemer');
        this.unassignedChauffeurs = this.allUsers.filter(x => x.functieNaam === 'Chauffeur');
        this.allChauffeurs = this.allUsers.filter(x => x.functieNaam === 'Chauffeur');
        allArbeiders = allArbeiders.filter(x => x.functieNaam !== 'Onderaannemer' && x.functieNaam !== 'Chauffeur');
        allArbeiders.sort((a, b) => a.name?.localeCompare(b.name));
        this.allArbeiders = allArbeiders;
        this.unassignedArbeiders = allArbeiders;
        let allPloegbazen = allArbeiders.filter(x => x.voorkeurArbeiders != null && x.voorkeurArbeiders.length !== 0);
        for(let ploegbaas of allPloegbazen){
          for(let i = 0; i < ploegbaas.voorkeurArbeiders.length; i++){
            let index = allArbeiders.findIndex(x => x._id === ploegbaas.voorkeurArbeiders[i].toString());
            if(index !== -1){
              ploegbaas.voorkeurArbeiders[i] = allArbeiders[index];
            } else {
              ploegbaas.voorkeurArbeiders.splice(i, 1);
              i--;
            }
          }
        }
        allPloegbazen = allPloegbazen.filter(x => x.voorkeurArbeiders.length !== 0);
        this.ploegen = [];
        allPloegbazen.forEach(ploegbaas => {
          this.ploegen.push({ploegbaas: ploegbaas, voorkeurArbeiders: ploegbaas.voorkeurArbeiders, enkeleArbeider: null});
          allArbeiders = allArbeiders.filter(x => x._id !== ploegbaas._id && !ploegbaas.voorkeurArbeiders.some(m => m._id === x._id));
        })

        let allRemainingArbeidersWithoutPloeg = allArbeiders.filter(x => x.functieNaam !== 'Chauffeur');
        allRemainingArbeidersWithoutPloeg.forEach(arbeider => {
          this.ploegen.push({enkeleArbeider: arbeider, ploegbaas: null, voorkeurArbeiders: null});
        })
      }),
      mapTo(1)
    ) : of(1);
    const werven$ = this.werven == null ? this.apiService.getWerven(true,false,false,false).pipe(
      tap(x => {
        this.werven = x as Werf[];
        this.werven  = this.werven.sort((a, b) => a.naam.localeCompare(b.naam));
      /*  for(let werf of this.werven){
          if(!werf.isActiefOpWerfPlanning && werf.uitvoeringsTermijn != null && werf.startDatum != null){
            let startDatum = new Date(werf.startDatum);
            let eindDatum = new Date(werf.startDatum);
            eindDatum.setDate(eindDatum.getDate() + werf.uitvoeringsTermijn);

            let difference = Math.abs(eindDatum.getTime() - startDatum.getTime());
            let days = difference / (1000 * 3600 * 24);
            if(days > werf.uitvoeringsTermijn){
              werf.isActiefOpWerfPlanning = true;
              this.apiService.updateIsWerfActief(werf._id, true).subscribe();
            }
          }
        }
       niet juist, WERKT NIET DOORDAT IK HET LAATSTE DAGBOEK NODIG HEB VAN ELKE WERF OM TE KUNNEN BEPALEN OF HET NOG LOPEND IS OF NIET
        */
        let verlofWerf = new Werf('VERLOF','verlof');
        verlofWerf.isActiefOpWerfPlanning = true;
        this.werven.unshift(verlofWerf);
        this.werven = this.werven.sort((a, b) => a.isActiefOpWerfPlanning === b.isActiefOpWerfPlanning ? 0 : a.isActiefOpWerfPlanning ? -1 : 1);
      }),
      mapTo(1)
    ) : of(1);
    const werfplanningOfMonth$ = this.maandWerfPlanning == null ? this.apiService.getMaandPlanningOfWerf(this.currentDate.getMonth(), this.currentDate.getFullYear()).pipe(
      tap(x => {
        this.maandWerfPlanning = x as MaandWerfPlanning;
        console.log(this.maandWerfPlanning);
      }),
      mapTo(1)
    ) : of(1);

    forkJoin([werven$, users$, werfplanningOfMonth$])
      .pipe(tap(([count1, count2, count3]) => this.isLoadedCount = count1 + count2 + count3 ))
      .subscribe();

    while(this.isLoadedCount < 3){
      await this.delay(50);
    }
    this.isLoaded = false;
    this.isSaving = false;
    this.isViewingDay = false;

    await this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());

    this.isLoaded = true;

  }
  toggleOnderaannemers() {
    this.showOnderaannemers = !this.showOnderaannemers;
  }
  toggleChauffeurs() {
    this.showChauffeurs = !this.showChauffeurs;
  }
  checkJaar($event: any) {
    this.isLoaded = false;
    if($event > this.currentDate.getFullYear()){
      this.maand = this.maanden[0];
      this.currentDate = new Date($event,0 , 1);
    } else if ($event < this.currentDate.getFullYear()) {
      this.maand = this.maanden[11];
      this.currentDate = new Date($event,11 , 1);
    }
    this.formService.latestMonth = this.currentDate.getMonth();
    this.formService.latestYear = this.currentDate.getFullYear();
    this.apiService.getMaandPlanningOfWerf(this.currentDate.getMonth(), this.currentDate.getFullYear()).subscribe(async x => {
      this.maandWerfPlanning = x as MaandWerfPlanning;
      await this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
      this.isLoaded = true;
    });

  }

  checkMaand($event: any) {
    this.isLoaded = false;
    let maandIndex = this.maanden.indexOf($event);
    this.currentDate = new Date(this.currentDate.getFullYear(), maandIndex, 1);
    this.apiService.getMaandPlanningOfWerf(this.currentDate.getMonth(), this.currentDate.getFullYear()).subscribe(async x => {
      this.maandWerfPlanning = x as MaandWerfPlanning;
      await this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
      this.isLoaded = true;
    });

  }
  previousMonth() {
    this.isLoaded = false;
    if(this.currentDate.getMonth() === 0){
      this.currentDate = new Date(this.currentDate.getFullYear() - 1, 11, 1);
      this.maand = this.maanden[this.currentDate.getMonth()];
      this.jaar = this.currentDate.getFullYear().toString();
    } else {
      this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
      this.maand = this.maanden[this.currentDate.getMonth()];
    }
    this.apiService.getMaandPlanningOfWerf(this.currentDate.getMonth(), this.currentDate.getFullYear()).subscribe(async x => {
      this.maandWerfPlanning = x as MaandWerfPlanning;
      await this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
      this.isLoaded = true;
    });
  }

  nextMonth() {
    this.isLoaded = false;
    if(this.currentDate.getMonth() === 11){
      this.currentDate = new Date(this.currentDate.getFullYear() + 1, 0, 1);
      this.maand = this.maanden[this.currentDate.getMonth()];
      this.jaar = this.currentDate.getFullYear().toString();
    } else {
      this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
      this.maand = this.maanden[this.currentDate.getMonth()];
    }
    this.apiService.getMaandPlanningOfWerf(this.currentDate.getMonth(), this.currentDate.getFullYear()).subscribe(async x => {
      this.maandWerfPlanning = x as MaandWerfPlanning;
      await this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
      this.isLoaded = true;
    });
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  async calculateDaysInMonth(month: number, year: number) {
    const datesObjects: any[] = [];
    while(this.company == null){
      await this.delay(50);
    }
    let eigenVerlofDagen = this.company.verlofDagen as Array<{fromDate: Date, toDate: Date}>;
    // Get the number of days in the month
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    // Loop through the days of the month and generate Date objects
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      // Reset the time to 0 hour, 0 minute, 0 seconds
      date.setHours(4, 0, 0, 0);
      if(date.getDay() !== 0 && date.getDay() !== 6){
        let soort;
        let isoDate = date.toISOString().split('T')[0];
        if(year === 2023){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2023.find(x =>x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2023.find(x =>x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          } else {
            soort = 'werken';
          }
        } else if(year === 2024){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2024.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2024.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }else {
            soort = 'werken';
          }
        }  else if(year === 2025){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2025.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2025.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          }  else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }else {
            soort = 'werken';
          }
        }
        if(eigenVerlofDagen != null && eigenVerlofDagen.length !== 0){
          let verlofDag = eigenVerlofDagen.find(x => {
            let fromIso = x.fromDate ? new Date(x.fromDate).toISOString().split('T')[0] : null;
            let toIso = x.toDate ? new Date(x.toDate).toISOString().split('T')[0] : null;

            if (toIso == null) {
              return fromIso === isoDate;
            } else {
              return fromIso <= isoDate && isoDate <= toIso;
            }
          });
          if (verlofDag) {
            soort = 'verlofdag';
          }
        }
        datesObjects.push({date: date, day: this.days[date.getDay()], soort: soort, werkenCount: 0,isoDate: date.toISOString().split('T')[0], werkUren: 0});
      }
    }

    this.lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    //change to friday if the last day is saturday or sunday
    if(new Date(year, month + 1, 0).getDay() === 0){
      this.lastDayOfMonth = this.lastDayOfMonth - 2;
    } else if(new Date(year, month + 1, 0).getDay() === 6){
      this.lastDayOfMonth = this.lastDayOfMonth -1;
    }

    for(let dag of datesObjects) {
      let werfPlanning = this.maandWerfPlanning?.dagWerfPlanningen?.find(x => x.isoDate === dag.isoDate);
      if(werfPlanning){
        dag.dagWerfplanningId = werfPlanning._id;
        dag.totalSelectedArbeiders = werfPlanning.totalSelectedArbeiders;
        dag.totalSelectedOnderaannemers = werfPlanning.totalSelectedOnderaannemers;
        dag.totalSelectedChauffeurs = werfPlanning.totalSelectedChauffeurs;
        dag.verlofArbeiders = werfPlanning.verlofArbeiders;
        dag.totalWerfPlanningen = werfPlanning.werfPlanningen?.length;
      } else {
        dag.verlofArbeiders = [];
      }
    }

    this.daysInMonth.next(datesObjects);
    let tempDaysInMonth = this.daysInMonth.getValue();
    this.daysInMonth$ = this.daysInMonth.pipe(
      map(daysArray => {
        // Calculate the index of the first day of the month
        const firstDayIndex = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), tempDaysInMonth[0].date.getDate()).getDay();

        // Create an array of empty slots with a special flag
        const emptySlots = new Array(Math.max(0, firstDayIndex - 1)).fill({ isEmpty: true });

        // Merge the emptySlots array with the actual daysArray
        return [...emptySlots, ...daysArray];
      })
    );


  }

  async bekijk(day: DayInMonth) {

      if(!this.isViewingDay){
        this.selectedArbeiders = [];
        this.hasChangedValue = false;
        this.formService.selectedIsoDateWerfPlanning = day.isoDate;
        this.selectedDate = day.date;
        this.currentDagWerfPlanning = this.maandWerfPlanning?.dagWerfPlanningen?.find(x => x.isoDate === day.isoDate);
        if(this.currentDagWerfPlanning){
          this._id = this.currentDagWerfPlanning._id;
        }
        this.werfPlanningen = this.werven.map(werf => {
          const planning = new WerfPlanning();
          if(this.currentDagWerfPlanning){
            const werfPlanning = this.currentDagWerfPlanning.werfPlanningen.find(wp => wp.werf._id === werf._id);
            if(werfPlanning){
              planning.selectedArbeiders = werfPlanning.selectedArbeiders;
              planning.opmerkingAanArbeiders = werfPlanning.opmerkingAanArbeiders === '' ? null : werfPlanning.opmerkingAanArbeiders;
            }  else {
              planning.selectedArbeiders = [];
              planning.opmerkingAanArbeiders = null;
              planning.selectedPloegen = [];
            }
          }  else {
            planning.selectedArbeiders = [];
            planning.opmerkingAanArbeiders = null;
            planning.selectedPloegen = [];
          }
          planning.werf = werf;
          return planning;
        });
        if(this.currentDagWerfPlanning){
          let verlofWerfIndex = this.werfPlanningen.findIndex(x => x.werf._id === 'verlof');

          this.unassignedArbeiders = this.allArbeiders.filter(x => !this.currentDagWerfPlanning?.werfPlanningen?.some(wp => wp.selectedArbeiders.map(arb => arb._id).includes(x._id)) &&
            !this.currentDagWerfPlanning.verlofArbeiders.map(x => x._id).includes(x._id));
          this.unassignedOnderaannemers = this.allOnderaannemers.filter(x => !this.currentDagWerfPlanning?.werfPlanningen?.some(wp => wp.selectedArbeiders.map(arb => arb._id).includes(x._id)) &&
            !this.currentDagWerfPlanning.verlofArbeiders.map(x => x._id).includes(x._id));
          this.unassignedChauffeurs = this.allChauffeurs.filter(x => !this.currentDagWerfPlanning?.werfPlanningen?.some(wp => wp.selectedArbeiders.map(arb => arb._id).includes(x._id)) &&
            !this.currentDagWerfPlanning.verlofArbeiders.map(x => x._id).includes(x._id));

          this.werfPlanningen[verlofWerfIndex].selectedArbeiders = this.currentDagWerfPlanning.verlofArbeiders;
        } else {

          if(this.maandWerfPlanning?.dagWerfPlanningen && this.maandWerfPlanning.dagWerfPlanningen.length > 0){
            this.reverseDagplanningen = [...this.maandWerfPlanning.dagWerfPlanningen];
            this.reverseDagplanningen.sort((a, b) => new Date(b.isoDate).getTime() - new Date(a.isoDate).getTime());
            this.copyWerfplanning = this.reverseDagplanningen[0];
          } else {
            let latestMaandWerfplanning = await this.apiService.getPrevious10Werfplanningen(day.isoDate) as MaandWerfPlanning;
            if(latestMaandWerfplanning?.dagWerfPlanningen && latestMaandWerfplanning?.dagWerfPlanningen.length > 0){
              this.reverseDagplanningen = [...latestMaandWerfplanning?.dagWerfPlanningen];
              this.reverseDagplanningen.sort((a, b) => new Date(b.isoDate).getTime() - new Date(a.isoDate).getTime());
              this.copyWerfplanning = this.reverseDagplanningen[0];
            }
          }

          let approvedVerlofAanvragen = await this.apiService.getApprovedVerlofDagenOfDate(day.isoDate) as Verlof[];

          if(approvedVerlofAanvragen && approvedVerlofAanvragen.length > 0){
            this.unassignedArbeiders = this.allArbeiders.filter(arbeider => !approvedVerlofAanvragen.some(verlof =>
              verlof.verlofOntvanger.toString() === arbeider._id
            ));
            this.unassignedOnderaannemers = this.allOnderaannemers.filter(arbeider => !approvedVerlofAanvragen.some(verlof =>
              verlof.verlofOntvanger.toString() === arbeider._id
            ));
            this.unassignedChauffeurs = this.allChauffeurs.filter(arbeider => !approvedVerlofAanvragen.some(verlof =>
              verlof.verlofOntvanger.toString() === arbeider._id
            ));
          }
          let verlofWerfIndex = this.werfPlanningen.findIndex(x => x.werf._id === 'verlof');
          if(verlofWerfIndex !== -1){
            this.werfPlanningen[verlofWerfIndex].selectedArbeiders = approvedVerlofAanvragen.map(x => this.allUsers.find(arb => arb._id === x.verlofOntvanger.toString()));
            //werfleiders kunnen ook verlof aanvragen en moeten momenteel niet op de werfplanning staan
            this.werfPlanningen[verlofWerfIndex].selectedArbeiders =  this.werfPlanningen[verlofWerfIndex].selectedArbeiders.filter(x => x?.name != null);
          }
          this.unassignedArbeiders = [...this.allArbeiders];
          this.unassignedOnderaannemers = [...this.allOnderaannemers];
          this.unassignedChauffeurs = [...this.allChauffeurs];
        }

        this.showOnderaannemers = this.unassignedOnderaannemers.length !== 0;
        this.showChauffeurs = this.unassignedChauffeurs.length !== 0;

        this.werfPlanningen = this.werfPlanningen.sort((a, b) => a.werf?.isActiefOpWerfPlanning === b.werf?.isActiefOpWerfPlanning ? 0 : a.werf?.isActiefOpWerfPlanning ? -1 : 1);

        this.isViewingDay = true;
      } else {

        if (this.hasChangedValue) {
          this.formService.previousRoute = null;
          const result = await this.dialog.open(HasChangedPopupComponent, {
            width: '450px',
            height: '200px',
            panelClass: 'mat-dialog-padding'
          }).afterClosed().toPromise();

          if (this.formService.previousRoute !== 'doorgaan') {
            return;
          }
        }
        this._id = null;
        this.isViewingDay = false;
        this.formService.selectedIsoDateWerfPlanning = null;

      }
  }




  convertDate(date: Date){
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + ('0' + date.getFullYear()).slice(-2);
  }

  async changeIsWerfActive(werfplanning: WerfPlanning) {
    if((werfplanning.selectedArbeiders && werfplanning.selectedArbeiders.length > 0) && werfplanning.werf.isActiefOpWerfPlanning === false){
       this.toastr.warning('Er zijn nog arbeiders toegewezen aan deze werf, gelieve deze eerst te verplaatsen', 'Werf is nog actief');
       werfplanning.werf.isActiefOpWerfPlanning = !werfplanning.werf.isActiefOpWerfPlanning;
        return;
    }
    console.log(' change actierf')
    this.apiService.updateIsWerfActief(werfplanning.werf._id, werfplanning.werf.isActiefOpWerfPlanning).subscribe();
    let werfIndex = this.werfPlanningen.findIndex(x => x.werf._id === werfplanning.werf._id);
    this.werfPlanningen[werfIndex].werf.isActiefOpWerfPlanning = werfplanning.werf.isActiefOpWerfPlanning;
    this.werfPlanningen  = this.werfPlanningen.sort((a, b) => a.werf.naam.localeCompare(b.werf.naam));
    let verlofWerf = this.werfPlanningen.find(x => x.werf._id === 'verlof');
    if(verlofWerf){
      this.werfPlanningen.splice(this.werfPlanningen.indexOf(verlofWerf), 1);
      this.werfPlanningen.unshift(verlofWerf);
    }
    this.werfPlanningen = this.werfPlanningen.sort((a, b) => a.werf.isActiefOpWerfPlanning === b.werf.isActiefOpWerfPlanning ? 0 : a.werf.isActiefOpWerfPlanning ? -1 : 1);
  }


  isSelected(item: User | Ploeg): boolean {
    if (this.sortChoiceBeschikbareArbeiders === 'ploegen') {
      return this.selectedPloegen.some(selected => selected.ploegbaas._id === (item as Ploeg).ploegbaas._id);
    } else {
      return this.selectedArbeiders.some(selected => selected._id === (item as User)._id);
    }
  }

  goToEditPloegen() {
     this.router.navigate(['/pages/ploegen-instellen']);
  }

  changeSortChoiceBeschikbareArbeiders() {
    console.log(this.sortChoiceBeschikbareArbeiders);
    if(this.sortChoiceBeschikbareArbeiders === 'ploegen'){

    }
  }


  toggleSelection(item: User | Ploeg, event: MouseEvent): void {
    event.preventDefault();
    if (this.sortChoiceBeschikbareArbeiders === 'ploegen') {
      this.togglePloegSelection(item as Ploeg);
    } else {
      this.toggleArbeiderSelection(item as User);
    }
  }

  toggleArbeiderSelection(arbeider: User): void {
    const index = this.selectedArbeiders.findIndex(selected => selected._id === arbeider._id);
    if (index === -1) {
      this.selectedArbeiders.push(arbeider);
    } else {
      this.selectedArbeiders.splice(index, 1);
    }
  }

  togglePloegSelection(ploeg: Ploeg): void {
    const index = this.selectedPloegen.findIndex(selected => selected.ploegbaas._id === ploeg.ploegbaas._id);
    if (index === -1) {
      this.selectedPloegen.push(ploeg);
    } else {
      this.selectedPloegen.splice(index, 1);
    }
  }

  onDragStarted() {
    this.isDragging = true;
  }

  onDragEnded() {
    this.isDragging = false;
  }


  dropArbeider(event: CdkDragDrop<User[]>) {
    this.hasChangedValue = true;

    if (event.previousContainer === event.container) {
      return;
    }

    // Determine which items to transfer
    const itemsToTransfer = this.getItemsToTransfer(event);

    // First, remove items from both werfplanningen and unassigned lists
    this.removeItemsFromAllSources(itemsToTransfer);

    // For unassigned lists, sort items into correct lists regardless of target
    if (this.isUnassignedList(event.container.data)) {
      // Sort items into correct unassigned lists
      itemsToTransfer.forEach(item => {
        if (this.isOnderaannemer(item)) {
          if (!this.unassignedOnderaannemers.some(u => u._id === item._id)) {
            this.unassignedOnderaannemers.push(item);
          }
        } else if(this.isChauffeur(item)) {
          if (!this.unassignedChauffeurs.some(u => u._id === item._id)) {
            this.unassignedChauffeurs.push(item);
          }
        } else {
          if (!this.unassignedArbeiders.some(u => u._id === item._id)) {
            this.unassignedArbeiders.push(item);
          }
        }
      });
    } else {
      // If target is a werfplanning, add items to target
      this.addItemsToTarget(event.container.data, itemsToTransfer);

      // Update target werfplanning
      const targetWerfplanning = this.werfPlanningen.find(
        wp => wp.selectedArbeiders === event.container.data
      );
      if (targetWerfplanning) {
        const existingIds = new Set(targetWerfplanning.selectedArbeiders.map(a => a._id));
        const uniqueNewItems = itemsToTransfer.filter(item => !existingIds.has(item._id));
        targetWerfplanning.selectedArbeiders.push(...uniqueNewItems);
      }
    }

    // Clear selection
    this.clearSelection();
    this.cdr.detectChanges();
  }

  private removeItemsFromAllSources(itemsToRemove: User[]) {
    const idsToRemove = new Set(itemsToRemove.map(item => item._id));

    // Remove from all werfplanningen
    this.werfPlanningen.forEach(werfplanning => {
      // Use reverse loop for safe splicing
      for (let i = werfplanning.selectedArbeiders.length - 1; i >= 0; i--) {
        const arbeider = werfplanning.selectedArbeiders[i];
        if (idsToRemove.has(arbeider._id)) {
          werfplanning.selectedArbeiders.splice(i, 1);
        }
      }
    });

    // Remove from unassigned arbeiders
    for (let i = this.unassignedArbeiders.length - 1; i >= 0; i--) {
      if (idsToRemove.has(this.unassignedArbeiders[i]._id)) {
        this.unassignedArbeiders.splice(i, 1);
      }
    }

    // Remove from unassigned chauffeurs
    for (let i = this.unassignedChauffeurs.length - 1; i >= 0; i--) {
      if (idsToRemove.has(this.unassignedChauffeurs[i]._id)) {
        this.unassignedChauffeurs.splice(i, 1);
      }
    }

    // Remove from unassigned onderaannemers
    for (let i = this.unassignedOnderaannemers.length - 1; i >= 0; i--) {
      if (idsToRemove.has(this.unassignedOnderaannemers[i]._id)) {
        this.unassignedOnderaannemers.splice(i, 1);
      }
    }
  }


  private addItemsToTarget(targetData: User[], itemsToAdd: User[]) {
    const existingIds = new Set(targetData.map(item => item._id));
    const uniqueItemsToAdd = itemsToAdd.filter(item => !existingIds.has(item._id));
    targetData.push(...uniqueItemsToAdd);
  }

  private isUnassignedList(list: User[]): boolean {
    return list === this.unassignedArbeiders || list === this.unassignedOnderaannemers || list === this.unassignedChauffeurs;
  }

  private clearSelection() {
    this.selectedArbeiders = [];
    this.isDragging = false;
  }
  protected isOnderaannemer(user: User): boolean {
    return user.functieNaam === 'Onderaannemer';
  }
  protected isChauffeur(user: User): boolean {
    return user.functieNaam === 'Chauffeur';
  }


  private getItemsToTransfer(event: CdkDragDrop<User[]>): User[] {
    if (this.selectedArbeiders.length > 0 && this.selectedArbeiders.includes(event.item.data)) {
      return [...this.selectedArbeiders];
    }
    return [event.item.data];
  }



  dropPloeg(event: CdkDragDrop<Ploeg[]>) {
    this.hasChangedValue = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const ploegToTransfer = event.item.data as Ploeg;

      if (event.previousContainer.data === this.ploegen) {
        // Find the corresponding Werfplanning by matching the selectedArbeiders array
        let indexOfWerfplanning = event.container.id.split('-')[1];
        const targetWerfplanning = this.werfPlanningen[indexOfWerfplanning];

        // Now we have the correct Werfplanning object
        this.assignPloegToWerf(ploegToTransfer, targetWerfplanning);
      } else {
        this.unassignPloegFromWerf(ploegToTransfer);
        this.ploegen.splice(event.currentIndex, 0, ploegToTransfer);
      }

      //this.updateUnassignedArbeiders();
      this.cdr.detectChanges();
    }
  }

  private assignPloegToWerf(ploeg: Ploeg, targetWerfplanning: WerfPlanning) {
    this.hasChangedValue = true;
    if (targetWerfplanning) {
      // Add all voorkeurArbeiders to the werfplanning
      if(targetWerfplanning.selectedPloegen == null) targetWerfplanning.selectedPloegen = [];
      targetWerfplanning.selectedPloegen.push(ploeg);

      // Remove the ploeg from the available ploegen
      let index;
      if(ploeg.enkeleArbeider != null){
        index = this.ploegen.findIndex(p => p.enkeleArbeider?._id === ploeg.enkeleArbeider?._id);
      } else {
        index = this.ploegen.findIndex(p => p.ploegbaas?._id === ploeg.ploegbaas?._id);
      }
      if (index !== -1) {
        this.ploegen.splice(index, 1);
      }
    }
  }

  private unassignPloegFromWerf(ploeg: Ploeg) {
    this.hasChangedValue = true;
    for (const werfplanning of this.werfPlanningen) {
      werfplanning.selectedArbeiders = werfplanning.selectedArbeiders.filter(
        arbeider => !ploeg.voorkeurArbeiders.some(pa => pa._id === arbeider._id)
      );
    }
  }



  dropInWerfplanning($event) {
    this.hasChangedValue = true;
    if($event.previousContainer.data[0].ploegbaas != null){
      this.dropPloeg($event);
    } else {
      this.dropArbeider($event);
    }
  }

  protected readonly faRemove = faRemove;

  disabbandonPloeg(werfplanningIndex: number, ploegbaasId: string) {
    let werfplanning = this.werfPlanningen[werfplanningIndex];
     this.werfPlanningen.splice(werfplanningIndex, 1);
     this.ploegen.push({ploegbaas: null, voorkeurArbeiders: null, enkeleArbeider: this.allArbeiders.find(x => x._id === ploegbaasId)});
     werfplanning.selectedPloegen.forEach(ploeg => {
       ploeg.voorkeurArbeiders.forEach(arbeider => {
          this.ploegen.push({ploegbaas: null, voorkeurArbeiders: null, enkeleArbeider: arbeider});
       });
     })
  }

  protected readonly faFloppyDisk = faFloppyDisk;

  saveDayWerfplanning() {
    if(this.isSaving){
      return;
    }
    this.isSaving = true;
    let dagWerfPlanning = new DagWerfPlanning();
    dagWerfPlanning.werfPlanningen = [...this.werfPlanningen];
    let verlofWerfIndex = dagWerfPlanning.werfPlanningen.findIndex(x => x.werf._id === 'verlof');
    let verlofWerf = dagWerfPlanning.werfPlanningen[verlofWerfIndex];
    dagWerfPlanning.verlofArbeiders = dagWerfPlanning.werfPlanningen[verlofWerfIndex].selectedArbeiders;

    dagWerfPlanning.werfPlanningen.splice(verlofWerfIndex, 1);

    dagWerfPlanning.werfPlanningen = dagWerfPlanning.werfPlanningen.filter(x => (x.selectedArbeiders == null || x.selectedArbeiders.length !== 0));

    dagWerfPlanning.totalSelectedArbeiders = this.werfPlanningen.map(x => x.selectedArbeiders.filter(a => a.functieNaam !== 'Onderaannemer' && a.functieNaam !== 'Chauffeur').length).reduce((a, b) => a + b, 0);
    dagWerfPlanning.totalSelectedChauffeurs = this.werfPlanningen.map(x => x.selectedArbeiders.filter(a => a.functieNaam === 'Chauffeur').length).reduce((a, b) => a + b, 0);
    dagWerfPlanning.totalSelectedOnderaannemers = this.werfPlanningen.map(x => x.selectedArbeiders.filter(a => a.functieNaam === 'Onderaannemer').length).reduce((a, b) => a + b, 0);
    if(verlofWerf.selectedArbeiders?.length > 0){
      let arbeidersOpVerlof = verlofWerf.selectedArbeiders.filter(a => a.functieNaam !== 'Onderaannemer' && a.functieNaam !== 'Chauffeur').length;
      let chauffeursOpVerlof = verlofWerf.selectedArbeiders.filter(a => a.functieNaam === 'Chauffeur').length;
      let onderaannemersOpVerlof = verlofWerf.selectedArbeiders.filter(a => a.functieNaam === 'Onderaannemer').length;
      if(arbeidersOpVerlof > 0){
        dagWerfPlanning.totalSelectedArbeiders -= arbeidersOpVerlof;
      }
      if(chauffeursOpVerlof > 0){
        dagWerfPlanning.totalSelectedChauffeurs -= chauffeursOpVerlof;
      }
      if(onderaannemersOpVerlof > 0){
        dagWerfPlanning.totalSelectedOnderaannemers -= onderaannemersOpVerlof;
      }
    }
    dagWerfPlanning.isoDate = this.formService.selectedIsoDateWerfPlanning;
    dagWerfPlanning.company_id = this.company._id;
    dagWerfPlanning._id = this._id;

    let dagIndex = this.maandWerfPlanning.dagWerfPlanningen.findIndex(x => x.isoDate === dagWerfPlanning.isoDate);
    if(dagIndex !== -1){
      this.maandWerfPlanning.dagWerfPlanningen[dagIndex] = dagWerfPlanning;
    }
        this.apiService.updateDagWerfplanning(dagWerfPlanning).subscribe(() => {
        this.isSaving = false;
          this.hasChangedValue = false;
          this.currentDagWerfPlanning = dagWerfPlanning;
        this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
        this.toastr.success('De werfplanning is succesvol gewijzigd', 'Succes');
        }, error => {
        this.isSaving = false;
        this.toastr.danger('Er is iets misgelopen bij het wijzigen van de werfplanning', 'Fout');
    });
  }

  protected readonly faCirclePlus = faCirclePlus;

  createDayWerfPlanning() {
    if(this.isSaving){
      return;
    }
    this.isSaving = true;
    let dagWerfPlanning = new DagWerfPlanning();
    dagWerfPlanning.werfPlanningen = [...this.werfPlanningen];

    let verlofWerfIndex = dagWerfPlanning.werfPlanningen.findIndex(x => x.werf._id === 'verlof');
    let verlofWerf = dagWerfPlanning.werfPlanningen[verlofWerfIndex];
    dagWerfPlanning.verlofArbeiders = dagWerfPlanning.werfPlanningen[verlofWerfIndex].selectedArbeiders;
    dagWerfPlanning.werfPlanningen.splice(verlofWerfIndex, 1);

    dagWerfPlanning.werfPlanningen = dagWerfPlanning.werfPlanningen.filter(x => (x.selectedArbeiders == null || x.selectedArbeiders.length !== 0));

    dagWerfPlanning.totalSelectedArbeiders = this.werfPlanningen.map(x => x.selectedArbeiders.filter(a => a.functieNaam !== 'Onderaannemer' && a.functieNaam !== 'Chauffeur').length).reduce((a, b) => a + b, 0);
    dagWerfPlanning.totalSelectedChauffeurs = this.werfPlanningen.map(x => x.selectedArbeiders.filter(a => a.functieNaam === 'Chauffeur').length).reduce((a, b) => a + b, 0);
    dagWerfPlanning.totalSelectedOnderaannemers = this.werfPlanningen.map(x => x.selectedArbeiders.filter(a => a.functieNaam === 'Onderaannemer').length).reduce((a, b) => a + b, 0);
    if(verlofWerf.selectedArbeiders?.length > 0){
      let arbeidersOpVerlof = verlofWerf.selectedArbeiders.filter(a => a.functieNaam !== 'Onderaannemer' && a.functieNaam !== 'Chauffeur').length;
      let chauffeursOpVerlof = verlofWerf.selectedArbeiders.filter(a => a.functieNaam === 'Chauffeur').length;
      let onderaannemersOpVerlof = verlofWerf.selectedArbeiders.filter(a => a.functieNaam === 'Onderaannemer').length;
      if(arbeidersOpVerlof > 0){
        dagWerfPlanning.totalSelectedArbeiders -= arbeidersOpVerlof;
      }
      if(chauffeursOpVerlof > 0){
        dagWerfPlanning.totalSelectedChauffeurs -= chauffeursOpVerlof;
      }
      if(onderaannemersOpVerlof > 0){
        dagWerfPlanning.totalSelectedOnderaannemers -= onderaannemersOpVerlof;
      }
    }
    dagWerfPlanning.isoDate = this.formService.selectedIsoDateWerfPlanning;
    dagWerfPlanning.company_id = this.company._id;
    this.apiService.creatDagWerfplanning(dagWerfPlanning, this.currentDate.getMonth(), this.currentDate.getFullYear()).subscribe(x => {
      this.isSaving = false;
      let result = x as {dagWerfPlanning: DagWerfPlanning, maandWerfPlanning: MaandWerfPlanning};
      this.currentDagWerfPlanning = result.dagWerfPlanning;
      if(result.maandWerfPlanning != null){
        this.maandWerfPlanning = result.maandWerfPlanning;
      } else {
        this.maandWerfPlanning.dagWerfPlanningen.push(result.dagWerfPlanning);
      }
      this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
      this.toastr.success('De werfplanning is succesvol aangemaakt', 'Succes');

      this._id = result.dagWerfPlanning._id.toString();
      this.hasChangedValue = false;
    }, error => {
      this.isSaving = false;
      this.toastr.danger('Er is iets misgelopen bij het aanmaken van de werfplanning', 'Fout');
    });
  }

  onDeleteClick() {
    const dialogRef = this.dialog.open(DeleteDialogWerfplanning, {
      width:'480px',
      panelClass: 'mat-dialog-padding'
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.apiService.deleteDagWerfPlanning(this._id, this.maandWerfPlanning._id).subscribe(() => {
          this.toastr.success('De werfplanning is succesvol verwijderd', 'Succes');
          this.maandWerfPlanning.dagWerfPlanningen = this.maandWerfPlanning.dagWerfPlanningen.filter(x => x._id !== this._id);
          this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
          this.isViewingDay = false;
          this._id = null;
        });
        }
      });

  }
  onCommentAreaClick(event: MouseEvent, i: number) {
      this.expandedTextareaId = `comment-${i}`;
  }



  kopieerDagPlanning() {
    console.log(this.copyWerfplanning)
    if(this.copyWerfplanning == null){
      return;
    }
    let werfplanningen = [...this.copyWerfplanning.werfPlanningen];
    if(werfplanningen && werfplanningen[0].werf._id === 'verlof'){
      werfplanningen.splice(0, 1);
    }
    let verlofArbeiders = [...this.copyWerfplanning.verlofArbeiders];
    let verlofWerfplanning = new WerfPlanning();
    verlofWerfplanning.selectedArbeiders = verlofArbeiders;
    verlofWerfplanning.werf = new Werf('VERLOF','verlof');
    werfplanningen.unshift(verlofWerfplanning);
    werfplanningen.forEach(x => x.werf.isActiefOpWerfPlanning = true);
    let alleWerven = [...this.werven];
    for(let werf of alleWerven){
      let werfplanning = werfplanningen.find(x => x.werf._id === werf._id);
      if(werfplanning == null) {
        let newWerfPlanning = new WerfPlanning();
        newWerfPlanning.werf = werf;
        newWerfPlanning.selectedArbeiders = [];
        newWerfPlanning.opmerkingAanArbeiders = '';
        werfplanningen.push(newWerfPlanning);
      }
    }
    let unassignedArbeiders = this.allArbeiders.filter(a =>
      !werfplanningen.some(wp => wp.selectedArbeiders.map(arb => arb._id).includes(a._id))
    );
    this.unassignedArbeiders = unassignedArbeiders;
    let unassignedChauffeurs = this.allChauffeurs.filter(a =>
      !werfplanningen.some(wp => wp.selectedArbeiders.map(arb => arb._id).includes(a._id))
    );
    this.unassignedChauffeurs = unassignedChauffeurs;
    let unassignedOnderaannemers = this.allOnderaannemers.filter(a =>
      !werfplanningen.some(wp => wp.selectedArbeiders.map(arb => arb._id).includes(a._id))
    );
    this.unassignedOnderaannemers = unassignedOnderaannemers;
    this.werfPlanningen = werfplanningen;
  }


  async generateDagPlanningPDF() {
    this.isSaving = true;
        this.isPrint = true;
        await this.delay(200);

        this.toggleDisplay();
        await this.delay(50);
        let options;
        let fileName
        fileName = 'Werfplanning-' + this.currentDagWerfPlanning.isoDate + '.pdf';
        options = {
          filename: fileName,
          image: {
            type: 'jpeg',
            quality: 1.0  // Maximum quality
          },
          html2canvas: {
            scale: 5,     // Higher scale for better quality
            useCORS: true
          },
          jsPDF: {orientation: 'portrait', format: 'a4', compressPdf: false},
          margin: [0.5, 0.5, 0.5, 0.5],
          pagebreak: { mode: 'avoid-all', avoid:  '.pageBreak'}
        };

        let element = document.getElementById('printContainer');

        await html2pdf().from(element).set(options).save();
        this.toggleDisplay();
        this.isSaving = false;
        this.isPrint = false;

  }
  toggleDisplay() {
    const element = document.getElementById('printContainer');
    if (element.style.display == 'block') {
      element.style.display = 'none';
    } else {
      element.style.display = 'block';
    }
  }

  async showOpmerkingAanArbeidersOfWerfplanning(werfplanning: WerfPlanning, index: number) {
    werfplanning.opmerkingAanArbeiders = '';
    this.werfPlanningen[index] = werfplanning;
    await this.delay(10);
    const textarea = document.getElementById('comment-' + index) as HTMLTextAreaElement;
    if (textarea) {
      textarea.focus();
    }
    this.onCommentAreaClick(new MouseEvent('click'), index);
  }
  selectAllFromWerf(werfplanning: any, event: MouseEvent) {
    // Voorkom dat de click event bubbelt
    event.stopPropagation();

    // Als alle arbeiders al geselecteerd zijn, deselecteer dan alles
    const allSelected = werfplanning.selectedArbeiders.every(
      (arbeider: User) => this.selectedArbeiders.some(
        selected => selected._id === arbeider._id
      )
    );

    if (allSelected) {
      // Deselecteer alle arbeiders van deze werf
      this.selectedArbeiders = this.selectedArbeiders.filter(
        selected => !werfplanning.selectedArbeiders.some(
          (arbeider: User) => arbeider._id === selected._id
        )
      );
    } else {
      // Selecteer alle arbeiders van deze werf
      const newSelection = werfplanning.selectedArbeiders.filter(
        (arbeider: User) => !this.selectedArbeiders.some(
          selected => selected._id === arbeider._id
        )
      );
      this.selectedArbeiders = [...this.selectedArbeiders, ...newSelection];
    }

    // Zorg dat Angular de wijzigingen detecteert
    this.cdr.detectChanges();
  }
  clearOpmerkingIfEmpty(werfplanning: WerfPlanning, index: number) {
      this.expandedTextareaId = null;
    if (werfplanning.opmerkingAanArbeiders == null || werfplanning.opmerkingAanArbeiders.trim() === '') {
      werfplanning.opmerkingAanArbeiders = null;
      this.werfPlanningen[index] = werfplanning;
    }
  }
  openDialogSendPushNotifications() {
    if(this.hasChangedValue){
      this.toastr.warning('Gelieve eerst de werfplanning op te slaan', 'Niet opgeslagen');
      return;
    }
    let allSelectedUsers = [];
    for(let werfplanning of this.werfPlanningen){
      if(werfplanning.werf._id !== 'verlof'){
        allSelectedUsers.push(...werfplanning.selectedArbeiders);
      }
    }
    //filter unique
    let uniqueUsers = allSelectedUsers.filter((v,i,a)=>a.findIndex(t=>(t._id === v._id))===i);
    let usersWithFcmToken = uniqueUsers.filter(x => x.fcmToken != null && x.fcmToken !== '');
    let usersWithoutFcmToken = uniqueUsers.filter(x => x.fcmToken == null || x.fcmToken === '');
    let arbeidersWithoutFcmToken = usersWithoutFcmToken.filter(x => x.functieNaam === 'Arbeider' || x.functieNaam === 'Ploegbaas' || x.functieNaam === 'Machinist');
    let onderaannemersWithoutFcmToken = usersWithoutFcmToken.filter(x => x.functieNaam === 'Onderaannemer');
    let chauffeursWithoutFcmToken = usersWithoutFcmToken.filter(x => x.functieNaam === 'Chauffeur');

    for(let user of usersWithFcmToken){
      let findWerfplanning = this.werfPlanningen.find(x => x.selectedArbeiders.find(y => y._id === user._id));
      findWerfplanning.werf = this.werven.find(x => x._id === findWerfplanning.werf._id);
      user.werf = findWerfplanning.werf;
      user.opmerkingAanArbeiders = findWerfplanning.opmerkingAanArbeiders;
    }

    let pushDialog = this.dialog.open(SendPushnotificationsDialog, {
      width: '900px',
      panelClass: 'mat-dialog-padding',
      data: { usersWithFcmToken: usersWithFcmToken, arbeidersWithoutFcmToken: arbeidersWithoutFcmToken,
        onderaannemersWithoutFcmToken: onderaannemersWithoutFcmToken, chauffeursWithoutFcmToken: chauffeursWithoutFcmToken,
      dagWerfPlanning: this.currentDagWerfPlanning}
    });
    pushDialog.afterClosed().subscribe(result => {
      if(result != null) {
        this.currentDagWerfPlanning.pushNotificationsSend = result.pushNotificationsSend;
        this.currentDagWerfPlanning.successfulPushNotficationUsers = result.successfulPushNotficationUsers;
        this.currentDagWerfPlanning.failedPushNotficationUsers = result.failedPushNotficationUsers;
        this.currentDagWerfPlanning.arbeidersWithoutFcmToken = result.arbeidersWithoutFcmToken;
      }
    })
  }
  protected readonly faArrowLeftFromArc = faArrowLeftFromArc;
  protected readonly faMinimize = faMinimize;
  protected readonly faArrowsMinimize = faArrowsMinimize;
  protected readonly faHardHat = faHardHat;
  protected readonly faHandshake = faHandshake;
  protected readonly faTruck = faTruck;
  protected readonly faArrowDown = faArrowDown;


  getUserNameAndRoleById(_id: string) {
    let user = this.allUsers.find(d => d._id === _id);
    return user?.name + ' - ' + user?.functieNaam;
  }
  protected readonly faCalendar = faCalendar;
  protected readonly faUmbrella = faUmbrella;
  protected readonly faBuilding = faBuilding;



  protected readonly faPaperPlaneTop = faPaperPlaneTop;

}
