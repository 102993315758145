<nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>
<div style="display:flex;flex-direction: row;justify-content: flex-start;align-items: center; margin:10px 0 0 20px; gap: 25px;">
  <h1 class="custom-title">
    <i style="font-size: 26px !important;" class="fa-solid fa-calendar-check bigFaIcon"></i>
    Werfplanning
  </h1>



  <div *ngIf="isViewingDay" class="day-header">
    <h2 class="day-planning-title">
      {{days[selectedDate.getDay()]}} {{convertDate(selectedDate)}}
    </h2>
  </div>
  <div *ngIf="!isViewingDay"  class="shorterFlex">
    <p class="sortText">Datum</p>
    <div class="colFlex">
      <div class="monthSelector">
        <button [disabled]="isViewingDay" (click)="previousMonth()" class="arrow-button"><i style="font-size: 23px" class="fa fa-arrow-left-long"></i></button>
        <nb-select [status]="'primary'" [disabled]="isViewingDay" class="bigSelect" (selectedChange)="checkMaand($event)" [ngModel]="maand">
          <nb-option *ngFor="let maand of maanden" [value]="maand">{{ maand }}</nb-option>
        </nb-select>
        <button [disabled]="isViewingDay" (click)="nextMonth()" class="arrow-button"><i style="font-size: 23px" class="fa fa-arrow-right-long"></i></button>
      </div>
      <nb-select [disabled]="isViewingDay" class="jaarSelect" (selectedChange)="checkJaar($event)" [ngModel]="jaar">
        <nb-option *ngFor="let jaar of jaren" value="{{ jaar }}">{{ jaar }}
        </nb-option>
      </nb-select>
    </div>
  </div>
 <!-- <button  mat-raised-button class="nav-button-black" (click)="goToEditPloegen()">Ploegen instellen</button>-->
  <button *ngIf="isViewingDay" mat-raised-button class="nav-button-black" (click)="bekijk(null)"><fa-icon class="normalIcon"  [icon]="faArrowLeftFromBracket"></fa-icon>Sluiten</button>
  <button *ngIf="_id != null" [disabled]="isSaving || !hasChangedValue"  (click)="saveDayWerfplanning()" mat-raised-button class="form-button-blue" color="primary">
    <div class="divInsideButton">
      <fa-icon class="normalIcon" [icon]="faFloppyDisk"></fa-icon>
      <span class="button-text">Opslaan</span></div>
  </button>
  <button *ngIf="_id == null"  [disabled]="isSaving"  (click)="createDayWerfPlanning()" mat-raised-button class="form-button-blue" color="primary">
    <div class="divInsideButton">
      <fa-icon class="normalIcon" [icon]="faCirclePlus"></fa-icon>
      <span class="button-text">Aanmaken</span></div>
  </button>
  <div style="display: flex;flex-direction: column; gap:15px;margin-left: 30px;justify-content: center;align-items: center">
    <button *ngIf="_id != null" mat-raised-button class="send-pushnotifications-button" (click)="openDialogSendPushNotifications()"> <div class="divInsideButton"> <fa-icon class="bigFaIcon" [icon]="faPaperPlaneTop"></fa-icon>
      <span class="button-text">Planning doorgeven</span></div></button>
    <button *ngIf="_id != null" mat-raised-button class="pdf-button" (click)="generateDagPlanningPDF()"> <div class="divInsideButton"> <fa-icon class="bigFaIcon" [icon]="faFilePdf"></fa-icon>
      <span class="button-text">Dagplanning</span></div></button>
  </div>
  <button *ngIf="_id != null" mat-raised-button class="delete-button" color="warn" type="button"
          (click)="onDeleteClick()"><i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></button>
</div>

<ng-container *ngIf="isLoaded">

  <div   *ngIf="!isViewingDay" class="calendar-header">
    <div class="day-header">Maandag</div>
    <div class="day-header">Dinsdag</div>
    <div class="day-header">Woensdag</div>
    <div class="day-header">Donderdag</div>
    <div class="day-header">Vrijdag</div>
  </div>
  <div  *ngIf="!isViewingDay" class="calendar-grid">
    <ng-container  *ngFor="let day of daysInMonth$ | async;">
      <div  (click)="bekijk(day)" *ngIf="!day.isEmpty; else emptyBlock"
            [ngClass]="day.soort !== 'vroegereDatum' && day.soort !== 'latereDatum' && day.soort !== 'feestdag' && day.soort !== 'verlofdag'? 'kanOpslaan': day.soort"
            class="day-container">
        <div class="topRowBox">
          <p class="datumText"><b>{{day.date.getDate()}}</b>  {{maanden[day.date.getMonth()] }}</p>
          <p class="soortTextSaved" [ngClass]="day.soort && (day.soort === 'verlofdag' || day.soort === 'feestdag') ? day.soort : ''" *ngIf="day.soort && (day.soort === 'verlofdag' || day.soort === 'feestdag')">{{day.soort.toUpperCase()}}</p>
        </div>
        <div *ngIf="day.day === 'Vrijdag'" class="break"></div>
        <div *ngIf="day.dagWerfplanningId" class="calendar-row">
          <p class="arbeidersText"><fa-icon style="color:#055497" [icon]="faMapLocationDot"></fa-icon> {{day.totalWerfPlanningen}} <span>werven</span></p>
          <p class="arbeidersText"><fa-icon style="color:#f97316" [icon]="faUmbrellaBeach"></fa-icon> {{day.verlofArbeiders.length}} <span>verlof</span></p>
        </div>
        <div *ngIf="day.dagWerfplanningId" class="calendar-row">
          <p *ngIf="day.totalSelectedArbeiders" class="arbeidersText"><fa-icon style="color:#4b5563" [icon]="faHardHat"></fa-icon> {{day.totalSelectedArbeiders}} <span>arb.</span></p>
          <p *ngIf="day.totalSelectedOnderaannemers" class="arbeidersText"><fa-icon style="color:#64748b" [icon]="faHandshake"></fa-icon> {{day.totalSelectedOnderaannemers}} <span>ond.</span></p>
          <p *ngIf="day.totalSelectedChauffeurs" class="arbeidersText"><fa-icon style="color:#6b7280" [icon]="faTruck"></fa-icon> {{day.totalSelectedChauffeurs}} <span>chauff.</span></p>
        </div>
      </div>
      <ng-template #emptyBlock><div  class="day-container empty"></div></ng-template>
    </ng-container>
  </div>



  <div class="outDivDayView" *ngIf="isViewingDay">


    <div *ngIf="currentDagWerfPlanning?.pushNotificationsSend" class="notification-status-container">
      <div class="notification-section success">
        <div class="status-header">
          <i class="fas fa-check-circle"></i>
          <h3>Gelukte notificaties <span class="badge">{{currentDagWerfPlanning.successfulPushNotficationUsers.length}}</span></h3>
        </div>
        <div class="users-list">
          <div *ngFor="let userId of currentDagWerfPlanning.successfulPushNotficationUsers" class="user-item">
            <i class="fas fa-user"></i>
            <span>{{getUserNameAndRoleById(userId)}}</span>
          </div>
        </div>
      </div>

      <div class="notification-section failed">
        <div class="status-header">
          <i class="fas fa-exclamation-circle"></i>
          <h3>Mislukte notificaties <span class="badge">{{currentDagWerfPlanning.failedPushNotficationUsers.length}}</span></h3>
        </div>
        <div class="users-list">
          <div *ngFor="let userId of currentDagWerfPlanning.failedPushNotficationUsers" class="user-item">
            <i class="fas fa-user"></i>
            <span>{{getUserNameAndRoleById(userId)}}</span>
          </div>
        </div>
      </div>
      <div class="notification-section failed">
        <div class="status-header">
          <i class="fas fa-user-slash"></i>
          <h3>Arbeiders zonder koppeling <span class="badge">{{currentDagWerfPlanning.arbeidersWithoutFcmToken?.length}}</span></h3>
        </div>
        <div class="users-list">
          <div *ngFor="let userId of currentDagWerfPlanning.arbeidersWithoutFcmToken" class="user-item">
            <i class="fas fa-user"></i>
            <span>{{getUserNameAndRoleById(userId)}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">

      <div cdkDropListGroup>
        <div class="static-section">
          <div class="actions-row">
          <!--  <div class="header-beschikbare-medewerkers">
              <mat-button-toggle-group   [(ngModel)]="sortChoiceBeschikbareArbeiders" name="sorteerOpties" aria-label="Sorteer opties">
                <mat-button-toggle (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="naam">Sorteer op naam</mat-button-toggle>
                <span style="height:20px;margin-left: 10px;margin-top: 12px;margin-right: 10px">Komende <i class="fas fa-arrow-right"></i></span>
                <mat-button-toggle [disabled]="true" (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="functie">Groeperen per functie</mat-button-toggle>
                <mat-button-toggle [disabled]="true" (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="ploegen">Groeperen per ploeg/voorkeurarbeiders</mat-button-toggle>
              </mat-button-toggle-group>
            </div>-->

          </div>
          <div  class="kopieerDiv">
            <ng-container *ngIf="_id == null">
              <button [disabled]="isSaving"  (click)="kopieerDagPlanning()" mat-raised-button class="copy-button" color="primary">
                <div class="divInsideButton">
                  <fa-icon class="normalIcon" [icon]="faCopy"></fa-icon>
                  <span class="button-text">Kopieer</span></div>
              </button>

              <mat-select [disabled]="isSaving" [panelClass]="'bigPanelKopieerSelect'" class="kopieerSelect" [ngModelOptions]="{standalone: true}" [(ngModel)]="copyWerfplanning">
                <mat-select-trigger>
                  <ng-container *ngIf="copyWerfplanning as dagplanning">
                    <!-- Use the same template but with different context -->
                    <ng-container *ngTemplateOutlet="werfplanningTemplate; context: { $implicit: dagplanning, isSelected: true }">
                    </ng-container>
                  </ng-container>
                </mat-select-trigger>

                <mat-option *ngFor="let dagplanning of reverseDagplanningen" [value]="dagplanning">
                  <ng-container *ngTemplateOutlet="werfplanningTemplate; context: { $implicit: dagplanning, isSelected: false }">
                  </ng-container>
                </mat-option>
              </mat-select>

              <ng-template #werfplanningTemplate let-dagplanning let-isSelected="isSelected">
                <div [ngClass]="!isSelected? 'extraMargin': ''" class="planning-entry">
                  <div class="planning-date">
                    <fa-icon [icon]="faCalendar" class="planning-icon"></fa-icon>
                    <span>{{dagplanning.isoDate | date: 'EEEE dd/MM/yyyy'}}</span>
                    <div style="margin-left: 12px" class="stat-item">
                      <fa-icon [icon]="faMapLocationDot" class="stat-icon"></fa-icon>
                      <span><b>{{dagplanning.werfPlanningen.length}}</b> Werven</span>
                    </div>
                  </div>
                  <div class="planning-stats">
                    <div class="stat-item">
                      <fa-icon [icon]="faUmbrellaBeach" class="stat-icon"></fa-icon>
                      <span><b>{{dagplanning.verlofArbeiders.length}}</b> Verlof</span>
                    </div>
                    <div class="stat-item">
                      <fa-icon [icon]="faHardHat" class="stat-icon"></fa-icon>
                      <span><b>{{dagplanning.totalSelectedArbeiders}}</b> Arbeiders</span>
                    </div>
                    <div class="stat-item">
                      <fa-icon [icon]="faHandshake" class="stat-icon"></fa-icon>
                      <span><b>{{dagplanning.totalSelectedOnderaannemers}}</b> onderaannemers</span>
                    </div>
                    <div class="stat-item">
                      <fa-icon [icon]="faTruck" class="stat-icon"></fa-icon>
                      <span><b>{{dagplanning.totalSelectedChauffeurs}}</b> chauffeurs</span>
                    </div>

                  </div>
                </div>
              </ng-template>
            </ng-container>

            <div *ngIf="!showChauffeurs" class="toggle-button" (click)="toggleChauffeurs()">
              <div class="button-content">
                <div class="icon-text">
                  <fa-icon [icon]="faArrowDown" class="arrowDown"></fa-icon>
                  <span>Toon chauffeurs  <span class="counter" *ngIf="unassignedChauffeurs.length > 0">{{unassignedChauffeurs.length}}</span></span>
                </div>
              </div>
            </div>

            <div *ngIf="!showOnderaannemers" class="toggle-button"  (click)="toggleOnderaannemers()">
              <div class="button-content">
                <div class="icon-text">
                  <fa-icon [icon]="faArrowDown" class="arrowDown"></fa-icon>
                  <span>Toon onderaannemers  <span class="counter" *ngIf="unassignedOnderaannemers.length > 0">{{unassignedOnderaannemers.length}}</span></span>
                </div>
              </div>
            </div>

          </div>

     <div class="outerContentRow">
                <!-- BESCHIKBARE CHAUFFEURS -->
                <div class="unassigned-list" *ngIf="showChauffeurs && sortChoiceBeschikbareArbeiders === 'naam'" cdkDropList [cdkDropListData]="unassignedChauffeurs" (cdkDropListDropped)="dropArbeider($event)">
                  <div class="section-header">
                    <div class="header-content">
                      <p class="beschikbareArbeidersText"> <fa-icon [icon]="faTruck" class="unassignedIcon"></fa-icon>
                        Chauffeurs<span class="counter" *ngIf="unassignedChauffeurs.length > 0">{{unassignedChauffeurs.length}}</span></p>
                      <fa-icon (click)="toggleChauffeurs()" [icon]="faArrowsMinimize" class="hideIcoon"></fa-icon>
                    </div>
                  </div>
                  <div *ngFor="let chauffeur of unassignedChauffeurs" class="example-box" [class.selected]="isSelected(chauffeur)" [class.dragging]="isDragging && isSelected(chauffeur)"
                       cdkDrag [cdkDragData]="chauffeur" (click)="toggleSelection(chauffeur, $event)" (cdkDragStarted)="onDragStarted()" (cdkDragEnded)="onDragEnded()">
                    <div class="worker-content">
                      <span class="worker-name">{{chauffeur.name}}</span>
                      <span class="selection-indicator" *ngIf="isSelected(chauffeur)">✓</span>
                    </div>
                    <!-- Preview when dragging -->
                    <div *cdkDragPreview class="drag-preview">
                      <ng-container *ngIf="selectedArbeiders.length > 1">
                        <div class="preview-count">{{selectedArbeiders.length}} chauffeurs</div>
                      </ng-container>
                      <ng-container *ngIf="selectedArbeiders.length <= 1">
                        {{chauffeur.name}}
                      </ng-container>
                    </div>
                  </div>
                </div>



       <!-- BESCHIKBARE ONDERAANNEMERS -->
            <div class="unassigned-list" *ngIf="showOnderaannemers && sortChoiceBeschikbareArbeiders === 'naam'" cdkDropList [cdkDropListData]="unassignedOnderaannemers" (cdkDropListDropped)="dropArbeider($event)">
              <div class="section-header">
                <div class="header-content">

                  <p class="beschikbareArbeidersText"><fa-icon [icon]="faHandshake" class="unassignedIcon"></fa-icon>
                    Onderaannemers<span class="counter" *ngIf="unassignedOnderaannemers.length > 0">{{unassignedOnderaannemers.length}}</span></p>
                  <fa-icon (click)="toggleOnderaannemers()" [icon]="faArrowsMinimize" class="hideIcoon"></fa-icon>
                </div>
              </div>
              <div *ngFor="let arbeider of unassignedOnderaannemers" class="example-box" [class.selected]="isSelected(arbeider)" [class.dragging]="isDragging && isSelected(arbeider)"
                   cdkDrag [cdkDragData]="arbeider" (click)="toggleSelection(arbeider, $event)" (cdkDragStarted)="onDragStarted()" (cdkDragEnded)="onDragEnded()">
                <div class="worker-content">
                  <span class="worker-name">{{arbeider.name}} ({{arbeider.firmaNaam}})</span>
                  <span class="selection-indicator" *ngIf="isSelected(arbeider)">✓</span>
                </div>
                <!-- Preview when dragging -->
                <div *cdkDragPreview class="drag-preview">
                  <ng-container *ngIf="selectedArbeiders.length > 1">
                    <div class="preview-count">{{selectedArbeiders.length}} arbeiders</div>
                  </ng-container>
                  <ng-container *ngIf="selectedArbeiders.length <= 1">
                    {{arbeider.name}}
                  </ng-container>
                </div>
              </div>
            </div>

          <!-- BESCHIKBARE ARBEIDERS -->
          <div class="unassigned-list" *ngIf="sortChoiceBeschikbareArbeiders === 'naam'" cdkDropList [cdkDropListData]="unassignedArbeiders" (cdkDropListDropped)="dropArbeider($event)">
            <div class="section-header">
              <div class="header-content">
                <p class="beschikbareArbeidersText"><fa-icon [icon]="faHardHat" class="unassignedIcon"></fa-icon>
                  Arbeiders<span class="counter" *ngIf="unassignedArbeiders.length > 0">{{unassignedArbeiders.length}}</span></p>
              </div>
            </div>
            <!-- Workers list with visual feedback -->
            <div *ngFor="let arbeider of unassignedArbeiders"
                 class="example-box"
                 [class.selected]="isSelected(arbeider)"
                 [class.dragging]="isDragging && isSelected(arbeider)"
                 cdkDrag
                 [cdkDragData]="arbeider"
                 (click)="toggleSelection(arbeider, $event)"
                 (cdkDragStarted)="onDragStarted()"
                 (cdkDragEnded)="onDragEnded()">

              <div class="worker-content">
                <span class="worker-name">{{arbeider.name}}</span>
                <span class="selection-indicator" *ngIf="isSelected(arbeider)">✓</span>
              </div>

              <!-- Preview when dragging -->
              <div *cdkDragPreview class="drag-preview">
                <ng-container *ngIf="selectedArbeiders.length > 1">
                  <div class="preview-count">{{selectedArbeiders.length}} arbeiders</div>
                </ng-container>
                <ng-container *ngIf="selectedArbeiders.length <= 1">
                  {{arbeider.name}}
                </ng-container>
              </div>
            </div>
          </div>
         <!-- <div  *ngIf="sortChoiceBeschikbareArbeiders === 'ploegen'" class="ploegen-list"
               cdkDropList
               [cdkDropListData]="ploegen"
               (cdkDropListDropped)="dropPloeg($event)">
            <div class="user-box extraSpecsPloeg"
                 *ngFor="let ploeg of ploegen"
                 cdkDrag
                 [cdkDragData]="ploeg"
                 (click)="toggleSelection(ploeg, $event)"
                 [class.selected]="isSelected(ploeg)">
              <ng-container *ngIf="ploeg.ploegbaas?._id">
                <div class="ploeg-header">
                  <p style="color:#3c3cff;margin-bottom: 0" class="text-lg font-large"><i class="fa fa-users-line" style="font-size: 16px;margin-right: 1px;color:#3c3cff;"></i>  {{ploeg.ploegbaas.name}}</p>
                  <button  class="closeButton" nbButton status="warning" >
                    <nb-icon style="font-size: 16px" pack="fa" icon="xmark"></nb-icon>
                  </button>
                </div>
                <div *ngFor="let member of ploeg.voorkeurArbeiders;let j = index" cdkDrag class="user-box" >
                  {{member.name}}
                </div>
              </ng-container>
              <ng-container *ngIf="ploeg.enkeleArbeider">
                {{ploeg.enkeleArbeider.name}}
              </ng-container>
            </div>
          </div>-->

        <div  class="scrollable-section">
          <div *ngFor="let werfplanning of werfPlanningen; let i = index">
            <div [ngClass]="werfplanning.werf?._id === 'verlof'? 'verlofWerf': werfplanning.werf.isActiefOpWerfPlanning ? 'actieveWerfTitle': 'werfTitle'" class="werfHeader">
              <div class="werf-info">
                <div *ngIf="werfplanning.selectedArbeiders.length > 0" class="arbeiders-counter">
                  <i class="fas fa-user"></i>
                  <span>{{werfplanning.selectedArbeiders.length}}</span>
                </div>
                <span *ngIf="werfplanning.werf?._id === 'verlof'"><i style="color:#d59300;font-size: 18px;" class="fa fa-umbrella-beach"></i></span>
                <span [ngClass]="werfplanning.werf?._id === 'verlof'? 'darkOrangeColor': ''"  class="werf-name">{{werfplanning.werf.naam}}</span>
              </div>
              <nb-checkbox class="werf-status" *ngIf="werfplanning.werf?._id !== 'verlof'"
                           [(ngModel)]="werfplanning.werf.isActiefOpWerfPlanning" (change)="changeIsWerfActive(werfplanning)">Actief?</nb-checkbox>
            </div>
            <div *ngIf="sortChoiceBeschikbareArbeiders === 'naam'" class="example-list werf-list"
                 cdkDropList id="werfplanning-{{i}}"
                 [cdkDropListData]="werfplanning.selectedArbeiders"
                 (cdkDropListDropped)="dropInWerfplanning($event)">
              <button *ngIf="werfplanning.selectedArbeiders.length > 0"
                      class="select-all-btn"
                      (click)="selectAllFromWerf(werfplanning, $event)">
                (De)selecteer alles
              </button>
              <div class="example-box"
                   *ngFor="let arbeider of werfplanning.selectedArbeiders"
                   [class.selected]="isSelected(arbeider)"
                   [class.dragging]="isDragging && isSelected(arbeider)"
                   cdkDrag
                   [cdkDragData]="arbeider"
                   (click)="toggleSelection(arbeider, $event)"
                   (cdkDragStarted)="onDragStarted()"
                   (cdkDragEnded)="onDragEnded()">
                <div class="worker-content">
                  <span class="worker-name">{{arbeider.name}} {{isOnderaannemer(arbeider)? '(' + arbeider.firmaNaam + ')' : ''}}</span>
                  <span class="selection-indicator" *ngIf="isSelected(arbeider)">✓</span>
                </div>
                <div *cdkDragPreview class="drag-preview">
                  <ng-container *ngIf="selectedArbeiders.length > 1">
                    <div class="preview-count">{{selectedArbeiders.length}} arbeiders</div>
                  </ng-container>
                  <ng-container *ngIf="selectedArbeiders.length <= 1">
                    {{arbeider.name}}
                  </ng-container>
                </div>
              </div>
              <div class="comment-container">
                <button *ngIf="werfplanning.opmerkingAanArbeiders == null && werfplanning.selectedArbeiders && werfplanning.selectedArbeiders.length > 0 &&
                 werfplanning.werf._id !== 'verlof'" class="comment-button"  nbButton status="primary" (click)="showOpmerkingAanArbeidersOfWerfplanning(werfplanning,i)">
                  <i class="fas fa-comment-dots comment-icon"></i></button>
                <div  (click)="onCommentAreaClick($event, i)" *ngIf="werfplanning.opmerkingAanArbeiders != null && werfplanning.selectedArbeiders && werfplanning.selectedArbeiders.length > 0" class="comment-form">
                  <p style="margin-bottom: 0">Opmerking/special instructies:</p>
                    <textarea id="comment-{{i}}" nbInput [(ngModel)]="werfplanning.opmerkingAanArbeiders" (keydown)="this.hasChangedValue = true;"
                              [class.expanded]="expandedTextareaId === 'comment-' + i" (focusout)="clearOpmerkingIfEmpty(werfplanning,i)" class="expandable-textarea"
                              placeholder="Voer opmerkingen/speciale instructies in voor de arbeiders" rows="3"></textarea>
                </div>
              </div>
            </div>
            <!--<div *ngIf="sortChoiceBeschikbareArbeiders === 'ploegen'" class="example-list werf-list"
                 cdkDropList id="werfplanning-{{i}}"
                 [cdkDropListData]="werfplanning"
                 (cdkDropListDropped)="dropInWerfplanning($event)">
              <div class="user-box extraSpecsPloeg"
                    *ngFor="let ploeg of werfplanning.selectedPloegen"
                    cdkDrag
                    [cdkDragData]="ploeg"
                    (click)="toggleSelection(ploeg, $event)"
                    [class.selected]="isSelected(ploeg)">

                <ng-container *ngIf="ploeg.ploegbaas?._id">
                  <div class="ploeg-header">
                    <p style="color:#3c3cff;margin-bottom: 0" class="text-lg font-large"><i class="fa fa-users-line" style="font-size: 16px;margin-right: 1px;color:#3c3cff;"></i>  {{ploeg.ploegbaas.name}}</p>
                    <button  class="closeButton" nbButton status="warning" >
                      <nb-icon style="font-size: 16px" pack="fa" icon="xmark"></nb-icon>
                    </button>
                  </div>
                  <div *ngFor="let member of ploeg.voorkeurArbeiders;let j = index" cdkDrag class="user-box" >
                    {{member.name}}
                  </div>
                </ng-container>
                <ng-container *ngIf="ploeg.enkeleArbeider">
                  {{ploeg.enkeleArbeider.name}}
                </ng-container>
              </div>
            </div>-->
            </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
<div *ngIf=" isPrint" style="margin-left:10px; display:none;font-size:12px !important;"  id="printContainer" >
  <ngx-dagplanning-pdf [dagWerfPlanning]="currentDagWerfPlanning" [currentDate]="currentDate" [company]="company"></ngx-dagplanning-pdf>
</div>
