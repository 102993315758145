<div class="dialog-container">
  <nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Even geduld..'" status="info"></nb-spinner>

  <div class="topRow">
    <h2 mat-dialog-title>Pushnotificaties versturen</h2>
    <label class="checkbox-container" [ngClass]="{'yellowBackground': !isConfirmed}">
      <input type="checkbox" [(ngModel)]="isConfirmed">
      <span  class="checkmark"></span>
      Vink dit vak aan voor<br>de notificaties te kunnen versturen
    </label>
    <button mat-raised-button [disabled]="isSaving || !isConfirmed"
            class="send-pushnotifications-button" (click)="sendPushNotificationsToEmployees()"> <div class="divInsideButton"> <fa-icon class="faIcon" [icon]="faPaperPlaneTop"></fa-icon>
      <span class="button-text">Planning versturen</span></div></button>
  </div>
  <button mat-raised-button class="nav-button-lightblue" (click)="showPushNotificationInfo = !showPushNotificationInfo"><fa-icon [icon]="faInfoCircle"></fa-icon>
    {{!showPushNotificationInfo ? 'Toon meer info': 'Verberg info'}}</button>

  <ngx-handleiding-werfplanning *ngIf="showPushNotificationInfo" (close)="showPushNotificationInfo = false"></ngx-handleiding-werfplanning>

  <mat-dialog-content style="display:flex;flex-direction: row;gap: 20px;padding-top: 0px !important;">
    <div class="section">
      <h3 class="section-title">
        <mat-icon class="success-icon">check_circle</mat-icon>
        Arbeiders met verbinding
        <span class="count">{{usersWithFcmToken.length}}</span>
      </h3>
      <div class="user-list" *ngIf="usersWithFcmToken.length">
        <div class="user-item" *ngFor="let user of usersWithFcmToken">
          <mat-icon>person</mat-icon>
          <span>{{user.name}}<br><small>{{user.functieNaam}}</small></span>
        </div>
      </div>
      <p *ngIf="!data.usersWithFcmToken.length" class="empty-message">
        Geen arbeiders met pushnotificaties gevonden
      </p>
    </div>

    <mat-divider></mat-divider>

    <div class="section">
      <h3 class="section-title">
        <mat-icon class="warning-icon">error_outline</mat-icon>
        Arbeiders zonder verbinding
        <span class="count">{{arbeidersWithoutFcmToken.length}}</span>
      </h3>
      <div class="user-list" *ngIf="arbeidersWithoutFcmToken.length">
        <div class="user-item" *ngFor="let user of arbeidersWithoutFcmToken">
          <mat-icon>person_outline</mat-icon>
          <span>{{user.name}}</span>
        </div>
      </div>
    </div>
    <div class="section">
      <h3 class="section-title">
        <mat-icon class="warning-icon">error_outline</mat-icon>
        Chauffeurs zonder verbinding
        <span class="count">{{chauffeursWithoutFcmToken.length}}</span>
      </h3>
      <div class="user-list" *ngIf="chauffeursWithoutFcmToken.length">
        <div class="user-item" *ngFor="let user of chauffeursWithoutFcmToken">
          <mat-icon>local_shipping</mat-icon>
          <span>{{user.name}}</span>
        </div>
      </div>
    </div>
    <!--<div class="section">
      <h3 class="section-title">
        <mat-icon class="warning-icon">error_outline</mat-icon>
        Onderaannemers zonder verbinding
      </h3>
      <div class="user-list" *ngIf="onderaannemersWithoutFcmToken.length">
        <div class="user-item" *ngFor="let user of onderaannemersWithoutFcmToken">
          <mat-icon>person_outline</mat-icon>
          <span>{{user.name}}</span>
        </div>
      </div>
    </div>-->
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Sluiten</button>
  </mat-dialog-actions>
</div>
