import { Injectable } from '@angular/core';
import {Dagrapport} from "../models/dagrapport";
import {User} from "../models/user";
import {Materiaal} from "../models/materiaal";
import {Werk} from "../models/werk";
import { Werkzaamheid } from '../models/werkzaamheid';
import { Company } from '../models/company';
import {Transportdagrapport} from "../models/transport-dagrapport";
import {Werf} from "../models/werf";
import {Machine} from "../models/machine";
import {Magazijntaak} from "../models/magazijntaak";
import {Reparatie} from "../models/reparatie";
import {Edigid} from "../models/E-digid/E-digid";
import {Bedrijf} from "../models/E-digid/bedrijf";
import {Schademelding} from "../models/schademelding";
import {DayWeatherData} from "../models/day-weather-data";

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public _hasPaused: boolean;
  comingFromAutoLogin: boolean;
  isAutoLogin: boolean;
  showTabs: string[];
  hideTabs: string[];
  currentDagRapport: Dagrapport;
  isComingFromLogin: boolean;
  handwerkers: User[] = [];
  werfleiders: User[] = [];
  materialen: Materiaal[];
  highestHour: number = 0;
  highestMinutes: number = 0;
  public werfFromDagrapporten: string;
  public userIdFromDagrapporten: string;
  dagrapportPageFilter: string;
  public isDelete: boolean;
  dagrapporten: Dagrapport[];
  previousScrollList;
  previousIndexScroll: number;
  _chosenPhoto: string;
  werfFromWerken: string;
  werken: Werk[];
  previousRoute: string;
  cancelDownload: boolean;
  activiteitFilter: string;
  comingFromAanwezigheidslijst: boolean;
  currentPageCount: number;
  company: Company;
  transportdagrapporten: Array<Transportdagrapport>;
  comingFromMeerwerken: boolean;
  meerwerkFromWerken: string;
  previousPageForScrollIndex: string;
  usersFilter: string;
  isInOnderaannemers: boolean;
  isSave: boolean;
  isGeneratingStaatDerVerlet: boolean;
  latestWerf: Werf;
  latestMonth: number;
  latestYear: number;
  materieelKeuze:  string;
  isNieuweReparatie: boolean;
  isNieuweMagazijntaak: boolean;
  allWerven: Werf[];
  allMachines: Array<Machine>;
  allWerkzaamheden: Array<Werkzaamheid>;
  allUsers: Array<User>;
  allOnderaannemers: User[];
  filterUsers: Array<User>;
  allChauffeurs: User[];
  nieuweMagazijnTaak: Magazijntaak;
  nieuweReparatie: Reparatie;
  dateFilter: string;
  reparatieFilter: string;
  reparatieSort: string;
  magTaakFilter: string;
  magTaakSort: string;
  lastestAddWerf: Werf;
  lastestAddMachine: Machine;
  latestAddMateriaal: Materiaal;
  currentEdigid: Edigid;
  eDigidPagina: string;
  allBedrijven: Bedrijf[];
  latestAddBedrijf: Bedrijf;

  verwerkingstechnieken = [
    'Storten op of in de bodem (bijvoorbeeld op een stortplaats)', // D1
    'Uitrijden (biodegradatie van vloeibaar of slibachtig afval in de bodem)', // D2
    'Injectie in de diepe ondergrond (bijvoorbeeld injectie van verpompbare afvalstoffen in putten, zoutkoepels of natuurlijk gevormde holten)', // D3
    'Opslag in waterbekkens (bijvoorbeeld het lozen van vloeibaar of slibachtig afval in putten, vijvers of lagunen)', // D4
    'Verwijderen op speciaal ingerichte locaties (bijvoorbeeld in afzonderlijke beklede afgedekte cellen die onderling en van de omgeving afgeschermd zijn)', // D5
    'Lozen in wateren, behalve zeeën en oceanen', // D6
    'Verwijderen in zeeën en oceanen, inclusief inbrengen in de bodem', // D7
    'Biologische behandeling op een niet elders in deze bijlage aangegeven wijze waardoor verbindingen of mengsels ontstaan die worden verwijderd op een van de onder D1 tot en met D12 vermelde methoden', // D8
    'Fysisch-chemische behandeling op een niet elders in deze bijlage aangegeven wijze, waardoor verbindingen of mengsels ontstaan die worden verwijderd op een van de onder D1 tot en met D12 vermelde methoden (bijvoorbeeld verdampen, drogen, calcineren)', // D9
    'Verbranding op het land', // D10
    'Verbranding op zee', // D11
    'Permanente opslag (bijvoorbeeld plaatsen van houders in mijnen)', // D12
    'Vermengen vóór een van de onder D1 tot en met D12 vermelde behandelingen', // D13
    'Herverpakken vóór een van de onder D1 tot en met D13 vermelde behandelingen', // D14
    'Opslag in afwachting van een van de onder D1 tot en met D14 vermelde behandelingen (met uitsluiting van voorlopige opslag voorafgaande aan inzameling op de plaats van productie)', // D15
    'Hoofdgebruik als brandstof of een andere wijze van energieopwekking', // R1
    'Terugwinning van oplosmiddelen', // R2
    'Recycling/terugwinning van organische stoffen die niet als oplosmiddel worden gebruikt (met inbegrip van compostbemesting en bemesting met andere biologisch omgezette stoffen)', // R3
    'Recycling/terugwinning van metalen en metaalverbindingen', // R4
    'Recycling/terugwinning van andere anorganische stoffen', // R5
    'Terugwinning van zuren of basen', // R6
    'Terugwinning van bestanddelen die worden gebruikt om vervuiling tegen te gaan', // R7
    'Terugwinning van bestanddelen uit katalysatoren', // R8
    'Herraffinage van olie en ander hergebruik van olie', // R9
    'Uitrijden voor landbouwkundige of ecologische verbetering', // R10
    'Gebruik van afvalstoffen die bij een van de onder R1 tot en met R10 genoemde behandelingen vrijkomen', // R11
    'Uitwisseling van afvalstoffen voor een van de onder R1 tot en met R11 genoemde behandelingen', // R12
    'Opslag van afvalstoffen bestemd voor een van de onder R1 tot en met R12 genoemde behandelingen (met uitsluiting van voorlopige opslag voorafgaande aan inzameling op de plaats van productie)' // R13
  ];
  D_en_R_codes = ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10', 'D11', 'D12', 'D13', 'D14', 'D15','R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9', 'R10', 'R11', 'R12', 'R13'];
  currentPageCountEdigid: number;
  edigidsFilter: string;
  dateFilterFrom: Date;
  dateFilterEnd: Date;
  chauffeurIdFromEdigidList: string;
  currentAfvalstoffenOmschrijving: string;
  isEigenBedrijf: boolean;
  userIdFromTransportDagrapporten: string;
  allEdigids: Edigid[];
  currentUser: User;
  lastPage: string;
  startDate: Date;
  endDate: Date;
  normalMaterialen: Materiaal[];
  verwerkerIdFromEdigidList: string;
  ihmIdFromEdigidList: string;
  euralCodeFromEdigidList: string;
  vervoerderFromEdigidList: string;
  lastTechniekerUserId: string;
  lastWerfFilterReparaties: string;
  lastMagazijnierUserId: string;
  reparatieWerven: Werf[];
  filteredReparaties: Reparatie[];
  allOnderhoudstechniekers: User[];
  lastMachineFilterReparaties: string;
  dateFilterTransport: string;
  edigidChauffeurs: User[];
  schademelding: Schademelding;
  schademeldingen: Schademelding[];
  werfFromSchademeldingen: string;
  allKleineMachines: Array<Machine>;
  schademeldingWerfFilter: string;
  lastestAddSchademelding: Schademelding;
  kopieerFormulier: Edigid;
  aanwezigheidsLijstChosenSoort: string;
  previousUserIdAanwezigheidslijst: string;
  previousWeekIndexAanwezigsheidslijst: number;
  werfPlanningDate: Date;
  selectedIsoDateWerfPlanning: string;
  currentPageCountTransport: number;
  ploegbaasIdFromDagrapporten: string;
  huidigeVoorkeurArbeiders: string;
  isInPloegen: boolean;
  isCreateAfvalstof: boolean;
  comingFromDagboeken: boolean;
  currentWeatherData: DayWeatherData;
  extraInfoOverIsWeerverlet: string;

  constructor() {}

  private smoothScrollTo(element: HTMLElement, to: number, duration: number) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };
    animateScroll();
  }

  private easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

}
