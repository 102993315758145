<div class="handleiding-container">
  <div class="header">
    <h2 style="margin-bottom: 0;width:fit-content">Voorbeeld push notificatie</h2>
  </div>

  <div class="sections">
    <div class="section" >
      <div class="section-content">
        <h3>Opbouw van de notificatie</h3>
        <div class="notification-structure">
          <div class="line-item">
            <span class="line-label">Bovenste regel</span>
            <p>Toont het volledige adres van de werf waar de arbeider verwacht wordt</p>
          </div>
          <div class="line-item">
            <span class="line-label">Onderste regel</span>
            <p>Bevat opmerkingen voor de arbeiders per werf</p>
          </div>
        </div>
      </div>
      <div class="section-image">
        <img src="assets/voorbeeldWerfplanningPopup.png" [alt]="'voorbeeldfoto'">
      </div>
    </div>
  </div>

  <div class="footer">
    <p class="note">* Push notificaties moeten ingeschakeld zijn op je apparaat om meldingen te ontvangen.</p>
  </div>
</div>
