import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faArrowLeftFromBracket} from "@awesome.me/kit-1d20bf365f/icons/modules/classic/solid";

@Component({
  selector: 'ngx-handleiding-werfplanning',
  templateUrl: './handleiding.component.html',
  styleUrls: ['./handleiding.component.scss'],
})
export class HandleidingComponent implements OnInit {
  public sections = [
    {
      title: 'Push Notificaties',
      content: 'Wanneer er een nieuwe werfplanning wordt aangemaakt of gewijzigd, ontvangen alle betrokken werknemers automatisch een push notificatie.',
      image: 'assets/images/push-notification-example.png'
    },
    {
      title: 'Planning Bekijken',
      content: 'Na het ontvangen van de notificatie kan je direct de details van je planning bekijken. Je ziet dan informatie over de werf, datum, shift en specifieke taken.',
      image: 'assets/images/planning-details.png'
    },
    {
      title: 'Status Updates',
      content: 'Het systeem houdt bij wie de notificatie heeft ontvangen en gelezen. Dit helpt bij het garanderen dat alle werknemers op de hoogte zijn van hun planning.',
      image: 'assets/images/status-updates.png'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  protected readonly faArrowLeftFromBracket = faArrowLeftFromBracket;

}
